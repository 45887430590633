var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"mini-variant":_vm.mini,"permanent":"","app":"","clipped":""}},[_c('v-divider'),(_vm.mini)?_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.menu),function(link){return [(!link.links)?_c('v-list-item',{key:JSON.stringify(link),attrs:{"to":link.to}},[_c('v-list-item',{attrs:{"link":""}},[_c('v-icon',[_vm._v(_vm._s(link.icon))])],1)],1):_vm._e()]})],2):_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.menu),function(link,index){return _c('v-list-item',{key:JSON.stringify(link),attrs:{"to":link.to}},[(link.links)?_c('v-expansion-panels',{attrs:{"mandatory":_vm.panelOpen[index] === true}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pl-1 py-0",staticStyle:{"display":"flex","align-items":"center"},style:(_vm.panelOpen[index] === true
                                ? 'background: #464642;'
                                : ''),attrs:{"color":"#0d0d0d"}},[_c('v-list-item-icon',{staticStyle:{"margin-right":"0","min-width":"58px"}},[_c('v-icon',[_vm._v(_vm._s(link.icon))])],1),_c('v-list-item-content',{staticStyle:{"width":"100%"}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(link.text)+" "),(
                                        link.links[0].to.name.includes(
                                            'reports'
                                        ) &&
                                        _vm.reportsUnreadCount.stream +
                                            _vm.reportsUnreadCount.user +
                                            _vm.reportsUnreadCount.story >
                                            0
                                    )?_c('span',{staticClass:"unread_badge"},[_vm._v(" "+_vm._s(_vm.reportsUnreadCount.stream + _vm.reportsUnreadCount.user + _vm.reportsUnreadCount.story)+" ")]):_vm._e(),(
                                        link.links[0].to.name.includes(
                                            'admin.withdrawals'
                                        ) &&
                                        _vm.withdrawalsUnreadCount.bank +
                                            _vm.withdrawalsUnreadCount.bitsafe >
                                            0
                                    )?_c('span',{staticClass:"unread_badge"},[_vm._v(" "+_vm._s(_vm.withdrawalsUnreadCount.bank + _vm.withdrawalsUnreadCount.bitsafe)+" ")]):_vm._e()])],1)],1),_c('v-expansion-panel-content',{attrs:{"color":"#0d0d0d"}},_vm._l((link.links),function(sublink,i){return _c('v-list-item',{key:i,attrs:{"link":true,"to":sublink.to}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(sublink.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(sublink.text)+" "),(
                                            sublink.to.name.includes(
                                                'admin.user-reports'
                                            ) && _vm.reportsUnreadCount.user > 0
                                        )?_c('span',{staticClass:"unread_badge"},[_vm._v(" "+_vm._s(_vm.reportsUnreadCount.user)+" ")]):_vm._e(),(
                                            sublink.to.name.includes(
                                                'admin.story-reports'
                                            ) &&
                                            _vm.reportsUnreadCount.story > 0
                                        )?_c('span',{staticClass:"unread_badge"},[_vm._v(" "+_vm._s(_vm.reportsUnreadCount.story)+" ")]):_vm._e(),(
                                            sublink.to.name.includes(
                                                'admin.stream-reports'
                                            ) &&
                                            _vm.reportsUnreadCount.stream > 0
                                        )?_c('span',{staticClass:"unread_badge"},[_vm._v(" "+_vm._s(_vm.reportsUnreadCount.stream)+" ")]):_vm._e(),(
                                            sublink.to.name.includes(
                                                'admin.withdrawals.bank-transfer'
                                            ) &&
                                            _vm.withdrawalsUnreadCount.bank > 0
                                        )?_c('span',{staticClass:"unread_badge"},[_vm._v(" "+_vm._s(_vm.withdrawalsUnreadCount.bank)+" ")]):_vm._e(),(
                                            sublink.to.name.includes(
                                                'admin.withdrawals.bitsafe'
                                            ) &&
                                            _vm.withdrawalsUnreadCount.bitsafe >
                                                0
                                        )?_c('span',{staticClass:"unread_badge"},[_vm._v(" "+_vm._s(_vm.withdrawalsUnreadCount.bitsafe)+" ")]):_vm._e()])],1)],1)}),1)],1)],1):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(link.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(link.text))])],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-navigation-drawer :mini-variant="mini" permanent app clipped>
        <v-divider></v-divider>
        <v-list v-if="mini" dense>
            <template v-for="link in menu">
                <v-list-item
                    :to="link.to"
                    v-if="!link.links"
                    :key="JSON.stringify(link)"
                >
                    <v-list-item link>
                        <v-icon>{{ link.icon }}</v-icon>
                    </v-list-item>
                </v-list-item>
            </template>
        </v-list>
        <v-list v-else dense>
            <v-list-item
                :to="link.to"
                v-for="(link, index) in menu"
                :key="JSON.stringify(link)"
            >
                <v-expansion-panels
                    v-if="link.links"
                    :mandatory="panelOpen[index] === true"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            color="#0d0d0d"
                            :style="
                                panelOpen[index] === true
                                    ? 'background: #464642;'
                                    : ''
                            "
                            style="display: flex; align-items: center"
                            class="pl-1 py-0"
                        >
                            <v-list-item-icon
                                style="margin-right: 0; min-width: 58px"
                            >
                                <v-icon>{{ link.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content style="width: 100%">
                                <v-list-item-title>
                                    {{ link.text }}

                                    <span
                                        class="unread_badge"
                                        v-if="
                                            link.links[0].to.name.includes(
                                                'reports'
                                            ) &&
                                            reportsUnreadCount.stream +
                                                reportsUnreadCount.user +
                                                reportsUnreadCount.story >
                                                0
                                        "
                                    >
                                        {{
                                            reportsUnreadCount.stream +
                                            reportsUnreadCount.user +
                                            reportsUnreadCount.story
                                        }}
                                    </span>

                                    <span
                                        class="unread_badge"
                                        v-if="
                                            link.links[0].to.name.includes(
                                                'admin.withdrawals'
                                            ) &&
                                            withdrawalsUnreadCount.bank +
                                                withdrawalsUnreadCount.bitsafe >
                                                0
                                        "
                                    >
                                        {{
                                            withdrawalsUnreadCount.bank +
                                            withdrawalsUnreadCount.bitsafe
                                        }}
                                    </span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content color="#0d0d0d">
                            <v-list-item
                                :link="true"
                                :to="sublink.to"
                                v-for="(sublink, i) in link.links"
                                :key="i"
                            >
                                <v-list-item-icon>
                                    <v-icon>{{ sublink.icon }} </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ sublink.text }}

                                        <span
                                            class="unread_badge"
                                            v-if="
                                                sublink.to.name.includes(
                                                    'admin.user-reports'
                                                ) && reportsUnreadCount.user > 0
                                            "
                                        >
                                            {{ reportsUnreadCount.user }}
                                        </span>
                                        <span
                                            class="unread_badge"
                                            v-if="
                                                sublink.to.name.includes(
                                                    'admin.story-reports'
                                                ) &&
                                                reportsUnreadCount.story > 0
                                            "
                                        >
                                            {{ reportsUnreadCount.story }}
                                        </span>
                                        <span
                                            class="unread_badge"
                                            v-if="
                                                sublink.to.name.includes(
                                                    'admin.stream-reports'
                                                ) &&
                                                reportsUnreadCount.stream > 0
                                            "
                                        >
                                            {{ reportsUnreadCount.stream }}
                                        </span>

                                        <span
                                            class="unread_badge"
                                            v-if="
                                                sublink.to.name.includes(
                                                    'admin.withdrawals.bank-transfer'
                                                ) &&
                                                withdrawalsUnreadCount.bank > 0
                                            "
                                        >
                                            {{ withdrawalsUnreadCount.bank }}
                                        </span>
                                        <span
                                            class="unread_badge"
                                            v-if="
                                                sublink.to.name.includes(
                                                    'admin.withdrawals.bitsafe'
                                                ) &&
                                                withdrawalsUnreadCount.bitsafe >
                                                    0
                                            "
                                        >
                                            {{ withdrawalsUnreadCount.bitsafe }}
                                        </span>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-list-item link v-else>
                    <v-list-item-icon>
                        <v-icon>{{ link.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ link.text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import menu from "./menu";

export default {
    name: "AdminDrawerMenu",
    props: ["mini"],
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),
    },
    data() {
        return {
            menu: menu,
            panelOpen: {},
            reportsUnreadCount: {},
            withdrawalsUnreadCount: {},
        };
    },
    mounted() {
        this.menu.forEach((item, index) => {
            if (item.links) {
                this.panelOpen[index] = item.links.some(
                    (sublink) =>
                        this.$router.resolve({
                            name: sublink.to.name,
                        }).href === this.$router.currentRoute.path
                );
            }
        });

        axios.get("/api/admin/reports/unread-count").then((response) => {
            this.reportsUnreadCount = response.data;
        });

        axios.get("/api/admin/withdrawals/unread-count").then((response) => {
            this.withdrawalsUnreadCount = response.data;
        });
    },
};
</script>

<style scoped lang="scss">
.v-list-item {
    padding-left: 5px;
    border-radius: 8px;
    overflow: hidden;
}

.v-list-item__icon,
.AdminMenu-icon {
    //margin-right: 6px !important;
}

.v-expansion-panel-header {
    padding-left: 10px;
}

.v-expansion-panel-header {
    min-height: auto;
    border-radius: 8px;
}

.v-expansion-panel::before {
    box-shadow: none;
}

.unread_badge {
    border-radius: 16px;
    background: #fd5312;
    padding: 0 8px;
}
</style>

<style lang="scss">
.v-expansion-panel-content__wrap {
    padding: 0 0 16px 16px !important;
}
</style>

export default [
    {
        to: { name: "admin.index" },
        icon: "mdi-home",
        text: "Dashboard",
    },
    {
        to: { name: "admin.users.index" },
        icon: "mdi-account",
        text: "Users",
    },
    {
        to: { name: "admin.wallet-balances.index" },
        icon: "mdi-wallet",
        text: "Wallet Balances",
    },
    {
        to: { name: "admin.verify-users.index" },
        icon: "mdi-account-multiple-check-outline",
        text: "Verify Users",
    },
    {
        to: { name: "admin.creators.index" },
        icon: "mdi-creation",
        text: "Noodzlers",
    },
    {
        icon: "mdi-comment-alert-outline",
        text: "Reports",
        links: [
            {
                to: { name: "admin.user-reports.index" },
                icon: "mdi-comment-account-outline",
                text: "User Reports",
            },
            {
                to: { name: "admin.story-reports.index" },
                icon: "mdi-comment-alert-outline",
                text: "Story Reports",
            },
            {
                to: { name: "admin.stream-reports.index" },
                icon: "mdi-comment-alert-outline",
                text: "Stream Reports",
            },
        ],
    },
    {
        to: { name: "admin.transactions.index" },
        icon: "mdi-transfer",
        text: "Transactions",
    },
    {
        icon: "mdi-open-in-new",
        text: "Withdrawals",
        links: [
            {
                to: { name: "admin.withdrawals.bitsafe.index" },
                icon: "mdi-auto-upload",
                text: "Bitsafe",
            },
            {
                to: { name: "admin.withdrawals.bank-transfer.index" },
                icon: "mdi-bank",
                text: "Bank transfer",
            },
            {
                to: { name: "admin.withdrawals.history.index" },
                icon: "mdi-history",
                text: "History",
            },
        ],
    },
    {
        to: { name: "admin.bank-transfers.index" },
        icon: "mdi-alpha-b-circle-outline",
        text: "Bank Transfers Book",
    },
    {
        to: { name: "admin.photos.index" },
        icon: "mdi-camera",
        text: "Photos",
    },
    {
        to: { name: "admin.videos.index" },
        icon: "mdi-video-vintage",
        text: "Videos",
    },
    {
        icon: "mdi-book-alphabet",
        text: "Dictionary",
        links: [
            {
                to: { name: "admin.tags.index" },
                icon: "mdi-tag",
                text: "Tags",
            },
            {
                to: { name: "admin.faq_sections.index" },
                icon: "mdi-frequently-asked-questions",
                text: "FAQ Sections",
            },
            {
                to: { name: "admin.pages.sections.index" },
                icon: "mdi-book-multiple-outline",
                text: "Page Sections",
            },
            {
                to: { name: "admin.prohibited_words.index" },
                icon: "mdi-alphabetical-variant-off",
                text: "Prohibited Words",
            },
            {
                to: { name: "admin.countries.index" },
                icon: "mdi-flag-outline",
                text: "Countries",
            },
            {
                to: { name: "admin.documents_types.index" },
                icon: "mdi-passport",
                text: "Documents Types",
            },
        ],
    },
];
